<template>
  <div
    class="SectionHeadline"
    :class="rootClasses">
    <span class="Main">{{ text }}</span>
    <span
      v-if="extraText"
      class="Exstra">{{ extraText }}</span>

  </div>
</template>

<script>
export default {
  name: 'SectionHeadline',
  props: {
    text: {
      type: String,
      required: true,
    },
    extraText: {
      type: String,
      required: false,
      default: '',
    },
    hideBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    rootClasses() {
      return {
        HasBorder: !this.hideBorder,
        NoBorder: this.hideBorder,
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
  .SectionHeadline
    display flex
    justify-content space-between
    align-items flex-end
    &.HasBorder
      padding-bottom 3px
      border-bottom 1px solid $color_grey_lighter
      margin-bottom 10px
    &.NoBorder
      margin-bottom 10px

  .Main
    font-size 0.875rem
    text-transform uppercase

  .Exstra
    color $color_grey
    font-size 0.75rem
</style>
