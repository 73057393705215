<template>
  <div
    class="CloseButton"
    @click="onCloseClick">
    <CloseIcon />
  </div>
</template>

<script>
import CloseIcon from '@/assets/svg/close.svg?inline'

export default {
  name: 'CloseButton',
  props: {
    closePath: {
      type: [String, Object],
      required: false,
      default: 'update-screening',
    },
  },
  computed: {
    screeningID() {
      return this.$route.params.screeningID
    },
  },
  methods: {
    onCloseClick() {
      if (this.closePath === 'update-screening') {
        this.$router.push({ name: 'ScreeningsUpdate', params: this.screeningID })
        return
      }
      this.$router.push(this.closePath)
    },
  },
  components: {
    CloseIcon,
  },
}
</script>

<style lang="stylus" scoped>
  .CloseButton
    position absolute
    top 0px
    right 0px
    box(50px)
    padding 18px
    cursor pointer
</style>
